import { Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Home from './Pages/Home';
import theme from './Contexts/Theme';
import AuthContextProvider from './Contexts/AuthContext';
import Product from './Pages/ProductPage';
import Products from './Components/Products';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

function App() {
  return (
    <ThemeProvider theme={theme} >
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<><Navbar /><Products /><Footer /></>} />
          <Route path="/products/:productSlug" element={<Product />} />
        </Routes>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
