import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Footer(){
    return (
        <Grid container sx={{bgcolor: "var(--primary)", py: 4, justifyContent: "center"}}>
            <Grid item md={3} xs={12} m={2}>
                <img src="https://cdn.shopify.com/s/files/1/0567/2855/1589/files/Logo_White_235x.png?v=1627124606" alt="logo"/>
            </Grid>
            <Grid item md={2}xs={12} m={2} sx={{flexDirection: "column", display: "flex"}}>
                <Typography sx={{fontSize: 18, color: "white", fontWeight:500, textTransform: "uppercase", mb:3}}>About us</Typography>
                <Link to="pages/deliver-return" className="footerLink">Delivery and Return</Link>
                <Link to="pages/faq" className="footerLink">FAQ</Link>
                <Link to="register" className="footerLink">Join our Affiliate Program</Link>
            </Grid>
            <Grid item md={2} xs={12} m={2} sx={{flexDirection: "column", display: "flex"}}>
                <Typography sx={{fontSize: 18, color: "white", fontWeight:500, textTransform: "uppercase", mb:3}}>Support</Typography>
                <Link to="pages/deliver-return" className="footerLink">Privacy Policy</Link>
                <Link to="pages/faq" className="footerLink">Refund Policy</Link>
                <Link to="register" className="footerLink">Terms of Services</Link>
                <Link to="register" className="footerLink">Wholesale Policy</Link>
            </Grid>
            <Grid item md={2} xs={12} m={2} sx={{flexDirection: "column", display: "flex"}}>
                <Typography sx={{fontSize: 18, color: "white", fontWeight:500, textTransform: "uppercase", mb:3}}>Contact Us</Typography>
                <Link to="pages/deliver-return" className="footerLink">Privacy Policy</Link>
                <Link to="pages/faq" className="footerLink">Refund Policy</Link>
                <Link to="register" className="footerLink">Terms of Services</Link>
                <Link to="register" className="footerLink">Wholesale Policy</Link>
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center", pt: 5}}>
                 <span className="copyright">Copyright © 2021 Ashvatha all rights reserved.</span>
            </Grid>
        </Grid>
    )
}

export default Footer