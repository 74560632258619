export const featuresArray = [
    {
        imageLink: "https://cdn.shopify.com/s/files/1/0567/2855/1589/files/natural-logo_eff1754f-81f3-43bd-a800-778a86a2ef86_180x.png?v=1621598985", 
        title: "100% NATUAL INGREDIENTS", 
        description: "We're an all natural brand which focuses on getting you unadultered products of the highest quality. We use only natural ingredients without any artificial preservatives, flavours, colours or chemicals committing to our Promise of Purity."
    },
    {
        imageLink: "https://cdn.shopify.com/s/files/1/0567/2855/1589/files/Ayurveda-logo_180x.png?v=1621599234", 
        title: "POWERED BY AYURVEDA", 
        description: "The essence of our superfoods comes from the wisdom of Ayurveda- the oldest science of health that holds bountiful secrets of wellness."
    },
    {
        imageLink: "https://cdn.shopify.com/s/files/1/0567/2855/1589/files/Women-emp-logo1_180x.png?v=1621600721", 
        title: "WOMEN EMPOWERMENT", 
        description: "We are a home-grown brand that was envisioned and established by a housewife. We value the dreams and hopes of millions of such women who need just a little push to uplift themselves, which is why our workforce constitues 80% of women labourers."
    },
    {
        imageLink: "https://cdn.shopify.com/s/files/1/0567/2855/1589/files/icon-4_5b8cc451-b5f4-4149-80d7-5c20391267e7_180x.png?v=1623913151", 
        title: "REVIVING INDIAN TRADITION", 
        description: "We put to use the traditional Indian recipes of our grandmothers in a contemporary way. By going back to our roots, we have come up with a striking combination of nutrition and nostalgia."
    }
]

