import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
import { AuthContext } from "../Contexts/AuthContext"
import "../CustomStyles/productPage.css"

function Product(){
    const {productSlug} = useParams()
    const context = useContext(AuthContext)

    const [product, setProduct] = useState({})
    const [quantityValue, setQuantityValue] = useState(1) 

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})

        async function getProduct(){
            const temp = await context.products.find(product => productSlug === product.slug)
            setProduct(temp)
        }
        getProduct()
        
    }, [productSlug, context.products])


    function pushToCart(){
        const productAttributes = {
            productID: product.productID,
            slug: product.slug,
            quantity: quantityValue,
            discountPrice: product.discountPrice,
            originalPrice: product.originalPrice,
            productTitle: product.productTitle,
            imageLink: product.imageLink
         }
        context.addItemToCart(productAttributes)
    }
    

    function incrementQuantity(){
        setQuantityValue(quantityValue + 1)
    }

    function decrementQuantity(){
        if(quantityValue === 1){
            return 
        }else{
            setQuantityValue(quantityValue - 1)
        }
    }

    return (
        <>
            <Navbar />
            {!product && <Box sx={{minHeight: "80vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress />
            </Box>}
            {product &&
            <Grid container spacing={3} sx={{justifyContent: "center", display: "flex", py: 5}}>
                <Grid item md={6} sx={{justifyContent: "center", display: "flex"}}>
                    <img style={{maxWidth: "70%"}} alt={product.productTitle} src={product.imageLink}></img>
                </Grid>
                <Grid item md={6} sx={{justifyContent: "flex-start", display: "flex"}}>
                    <Box sx={{maxWidth: 500, px: 2}}>
                        <Typography variant="h1" fontSize={25} fontWeight={600}>{product.productTitle}</Typography>
                        <Box my={1} sx={{fontSize: 20}}><span className="originalPrice">Rs. {product.originalPrice}.00</span><span className="discountPrice">Rs. {product.discountPrice}.00</span></Box>
                        <Typography fontSize={14} mb={2}>
                        Tax included.
                        </Typography>

                        <Typography variant="p" fontSize={14} lineHeight={2}>
                            {product.productDescription}
                        </Typography>
                        <Typography my={2} fontWeight={600} fontSize={14}>
                        Contains approximately 100 servings. 
                        </Typography>
                        <Box sx={{display: "flex", flexDirection: "row", mt:1}}>
                            <div className="quantity">
                                <button onClick={() => decrementQuantity()}>-</button>
                                <input type="text" value={quantityValue} onChange={(e) => {setQuantityValue(e.target.value)}}/>
                                <button onClick={() => incrementQuantity()}>+</button>
                            </div>
                            <button className="addCartBtn" onClick={() => pushToCart(product)}>Add to cart</button>
                        </Box>
                        <Box my={2}>
                            <img style={{maxWidth: 350}} alt="" src="https://cdn.shopify.com/s/files/1/0567/2855/1589/files/icons-01_750x_4cfe8fa0-44a1-49f6-9fad-56615ec58e4f_480x.jpg?v=1623912684" />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{color: "var(--primary)", textAlign: "center", fontWeight: 700, fontSize: 22}}>Our Promise</Typography>
                </Grid>
                
                <Grid item xs={12} md={4} sm={6} sx={{justifyContent: "center", display: "flex"}}><img alt="" className="promiseImg" src= "https://cdn.shopify.com/s/files/1/0567/2855/1589/t/4/assets/01aeb7568936--our-promise-01.jpg?v=1623912426" /></Grid>
                <Grid item xs={12} md={4} sm={6} sx={{justifyContent: "center", display: "flex"}}><img alt="" className="promiseImg"  src="https://cdn.shopify.com/s/files/1/0567/2855/1589/t/4/assets/cb8095c77b25--our-promise-02.jpg?v=1623912423" /></Grid>
                
            </Grid>            
            }
            <Footer />
        </>
    )
}

export default Product