import { Box, Drawer, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import { ReactComponent as CloseIcon } from "../Icons/x.svg";

function SidebarCart() {
  const context = useContext(AuthContext);

  return (
    <Drawer anchor="right" open={context.cart.show}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid #c9c9c9",
        }}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            color: "var(--primary)",
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          Your Cart
        </Typography>
        <CloseIcon
          style={{ color: "gray", cursor: "pointer" }}
          onClick={() => context.closeCart()}
        />
      </Box>
      <Box py={1} sx={{ overflowY: "scroll", mb: 17 }}>
        {context.cart.items.map((item) => {
          return (
            <Grid
              container
              key={item.productID}
              sx={{
                display: "flex",
                px: 2,
                py: 1,
                borderBottom: "1px solid #cecece",
              }}
            >
              <Grid
                item
                xs={3}
                pr={2}
                sx={{ display: "flex", placeContent: "center" }}
              >
                <img
                  style={{ width: "100px", backgroundColor: "red" }}
                  alt={item.productTitle}
                  src={item.imageLink}
                />
              </Grid>

              <Grid item xs={9}>
                <Box>
                  <Typography fontWeight={500}>{item.productTitle}</Typography>
                  <Typography>Rs. {item.discountPrice}</Typography>
                </Box>
                <div className="sidebarQuantity">
                  <div className="quantityBtns">
                    <button>-</button>
                    <input value={item.quantity} readOnly />
                    <button>+</button>
                  </div>
                  <span>Rs. {item.discountPrice}</span>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          padding: 3,
          bgcolor: "#f8f4ec",
          borderTop: "1px solid #9c5430",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={700}>Sutotal</Typography>
          <Typography fontWeight={700}>
            Rs.{" "}
            {context.cart.totalAmount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            .00
          </Typography>
        </Box>
        <button className="checkOutBtn">Checkout </button>
      </Box>
    </Drawer>
  );
}

export default SidebarCart;
