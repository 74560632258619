import { createContext, useEffect, useState } from "react"
import {getFirestore, collection, getDocs } from "firebase/firestore";
import { firebaseConfig } from "../Utils/firebase";
import { initializeApp } from "@firebase/app";
import SidebarCart from "../Components/SidebarCart";

export const AuthContext = createContext()

function AuthContextProvider({children}){    
    const app = initializeApp(firebaseConfig)
    const db = getFirestore(app);

    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [cart, setCart] = useState({items: [], show: false, totalAmount: 0})
    

    useEffect(() => {
        async function getProducts(){
            const productsArray = []
            const querySnapshot = await getDocs(collection(db, "Products"));
            querySnapshot.forEach((doc) => {
                productsArray.push({productID: doc.id, ...doc.data()})
            });
            setLoading(false)
            setProducts(productsArray)
        }
        getProducts()

    }, [db])

    function addItemToCart(item){
        console.log(item)
        if(cart.items.find(product => product.productID === item.productID) === undefined){
            const tempCart = cart
            tempCart.totalAmount = Number(tempCart.totalAmount) + Number(Number(item.discountPrice) * Number(item.quantity))
            tempCart.items.push(item)
            setCart({...tempCart, show: true})
        }else{
            setCart({...cart, show: true})
        }
        
    }

    function incrementItemQuantity(item){

    }

    function closeCart(){
        setCart({...cart, show: false})
    }


    const value = {
        products,
        cart,
        loading,
        addItemToCart,
        closeCart
    }
    return (
        <AuthContext.Provider value={value}>
            {<SidebarCart/>}
            {children}
        </AuthContext.Provider>
    )   
}

export default AuthContextProvider