import { ReactComponent as UserIcon } from "../Icons/user.svg"
import { ReactComponent as SearchIcon } from "../Icons/search.svg"
import { ReactComponent as WishListIcon } from "../Icons/heart.svg"
import { ReactComponent as CartIcon } from "../Icons/shopping-cart.svg"
import {ReactComponent as MenuIcon} from "../Icons/menu.svg"
import {ReactComponent as CloseIcon} from "../Icons/x.svg"

import { useState } from "react"
import { Link } from "react-router-dom"

function Navbar(){
    const [mobileNavOpen, setMobileNavOpen] = useState(false)

    return(
        <nav className="nav">
            <div className="menuBtn">
                <MenuIcon  onClick={() => setMobileNavOpen(!mobileNavOpen)}/>
            </div>

            <div>
                <img className="logo" alt="logo" src="https://cdn.shopify.com/s/files/1/0567/2855/1589/files/lOGO_5_230x.png?v=1627036579" />
            </div>
            <div className="navMenu">
                <div className="navMenuLinks">
                    {mobileNavOpen && 
                    <>
                        <div className="mobileMenu">
                            <CloseIcon style={{float: "right"}} onClick={() => setMobileNavOpen(!mobileNavOpen)}/>
                            <Link to="/">Shop</Link>
                            <Link to="/">Combos</Link>
                            <Link to="/">Premium Gift Boxes</Link>
                            <Link to="/" >Our Story</Link>
                            <Link to="/" >Blog</Link>
                        </div>
                    </>}
                    
                    <div className="desktopNav">
                        <Link to="/" >Shop</Link>
                        <Link to="/" >Combos</Link>
                        <Link to="/" >Premium Gift Boxes</Link>
                        <Link to="/" >Our Story</Link>
                        <Link to="/" >Blog</Link>
                    </div>
    
                </div>
            </div>
            <div className="icons">
                <SearchIcon />
                <UserIcon />
                <WishListIcon />
                <CartIcon />                
            </div>
        </nav>
    )
}

export default Navbar