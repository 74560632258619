import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { AuthContext } from "../Contexts/AuthContext"
import { Link } from "react-router-dom"

export default function Products(){
    const context = useContext(AuthContext)
    return(
        <>
        <Grid container spacing={2} sx={{py: 8, px:{xs:3, md: 20}, display: "flex", justifyContent: "center"}}>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Typography fontWeight={600} fontSize={25}>Our Products</Typography>
            </Grid>
            {context.products.length === 0 && 
                <Box sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box>}
            {context.products.map(product => {
                return (
                    <Grid item xs={12} md={4} sm={6} key={product.slug} sx={{display: "flex", justifyContent: "center"}}>
                        <Stack>
                        <Link to={`/products/${product.slug}`} ><img className="productImage" alt="" src={product.imageLink}/></Link>
                        <Link className="productLink" to={`/products/${product.slug}`} >
                            {product.productTitle}
                        </Link>
                        <Typography fontSize={13}>{`from Rs. ${product.originalPrice}`}</Typography>
                        </Stack>
                    </Grid>
                )
            })}
        </Grid>
        </>
    )
}