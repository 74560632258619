import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import "../CustomStyles/homepage.css";
import { Box, Grid, Rating, Typography } from "@mui/material";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { featuresArray } from "../Arrays";
import { useEffect } from "react";
import Products from "../Components/Products";

function Home() {
  useEffect(() => {
    SwiperCore.use([Autoplay, Pagination]);
  }, []);

  return (
    <>
      <Navbar />
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div className="slide slide1">
            <div className="content">
              Traditional indian superfoods for holistic health
            </div>
            <button>Click here</button>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide slide2">
            <div className="content">Launching new festive gift boxes</div>
            <button>Shop now</button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide slide3">
            <div className="content">
              Refreshing aromatic indian chai masala till the last measure
            </div>
            <button>Shop now</button>
          </div>
        </SwiperSlide>
      </Swiper>

      {/* Features */}
      <Grid
        container
        spacing={4}
        sx={{
          bgcolor: "#f8f4ec",
          py: 10,
          px: { xs: 3, md: 20 },
          color: "#9c5430",
          textAlign: "justify",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography fontSize={23}>
            PREMIUM SUPERFOODS, CRAFTED WITH LOVE
          </Typography>
        </Grid>

        {featuresArray.map((feature) => {
          return (
            <Grid item md={3} key={feature.title}>
              <img
                className="featureImage"
                alt=""
                src={feature.imageLink}
              ></img>
              <Typography mb={1} fontWeight={600}>
                {feature.title}
              </Typography>
              <Typography fontSize={14}>{feature.description}</Typography>
            </Grid>
          );
        })}
      </Grid>

      {/* Our Products */}
      <Products />

      {/* Info */}
      <Grid container sx={{ bgcolor: "#f8f4ec", color: "#9c5430" }}>
        <Grid
          item
          md={6}
          sx={{ alignItems: "center", display: "flex", p: { xs: 3, md: 16 } }}
        >
          <Box>
            <Typography fontWeight={700} fontSize={30}>
              ASHVATHA
            </Typography>
            <Typography fontSize={20} mb={2}>
              Reviving Indian Tradition
            </Typography>
            <Typography fontSize={13} sx={{ color: "black", my: 2 }}>
              At Ashvatha, we believe in taking a more holistic approach to
              Life, being Life and Health-conscious rather than Disease and
              Treatment-oriented.
            </Typography>
            <Typography fontSize={13} sx={{ color: "black", my: 2 }}>
              Combining Ancient Indian Ayurvedic wisdom and Modern Science, we
              create products that are not just healthier additions to your
              daily lifestyle but also taste really good.
            </Typography>
            <button className="OurStoryBtn">Our Story</button>
          </Box>
        </Grid>
        <Grid item md={6}>
          <img
            style={{ width: "100%" }}
            alt=""
            src="https://cdn.shopify.com/s/files/1/0567/2855/1589/files/Untitled_design_1080x.jpg?v=1629136822"
          />
        </Grid>
      </Grid>

      {/* Customer Reviews */}
      <Grid
        container
        sx={{
          bgcolor: "#9c5430",
          py: 8,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{ color: "white" }}
            fontSize={25}
            fontWeight={600}
            mb={5}
          >
            CUSTOMER REVIEWS
          </Typography>
        </Grid>

        <Grid item md={2} sx={{ bgcolor: "white", p: 4, m: 2 }}>
          <Rating
            value={5}
            sx={{ fontSize: 15, color: "#9c5430", my: 2 }}
            readOnly
          />
          <Typography fontSize={14}>
            My children loved Milk Booster and are taking milk on regular basis
            now because of milk booster. I am also taking Kadha daily, so
            relaxing for throat. 10/10 from my side.
          </Typography>
          <Typography fontSize={14} fontWeight={600} mt={2}>
            Ms Sarita Khurana
          </Typography>
        </Grid>
        <Grid item md={2} sx={{ bgcolor: "white", p: 4, m: 2 }}>
          <Rating
            value={5}
            sx={{ fontSize: 15, color: "#9c5430", my: 2 }}
            readOnly
          />
          <Typography fontSize={14}>
            My children loved Milk Booster and are taking milk on regular basis
            now because of milk booster. I am also taking Kadha daily, so
            relaxing for throat. 10/10 from my side.
          </Typography>
          <Typography fontSize={14} fontWeight={600} mt={2}>
            Ms Sarita Khurana
          </Typography>
        </Grid>
        <Grid item md={2} sx={{ bgcolor: "white", p: 4, m: 2 }}>
          <Rating
            value={5}
            sx={{ fontSize: 15, color: "#9c5430", my: 2 }}
            readOnly
          />
          <Typography fontSize={14}>
            My children loved Milk Booster and are taking milk on regular basis
            now because of milk booster. I am also taking Kadha daily, so
            relaxing for throat. 10/10 from my side.
          </Typography>
          <Typography fontSize={14} fontWeight={600} mt={2}>
            Ms Sarita Khurana
          </Typography>
        </Grid>
        <Grid item md={2} sx={{ bgcolor: "white", p: 4, m: 2 }}>
          <Rating
            value={5}
            sx={{ fontSize: 15, color: "#9c5430", my: 2 }}
            readOnly
          />
          <Typography fontSize={14}>
            My children loved Milk Booster and are taking milk on regular basis
            now because of milk booster. I am also taking Kadha daily, so
            relaxing for throat. 10/10 from my side.
          </Typography>
          <Typography fontSize={14} fontWeight={600} mt={2}>
            Ms Sarita Khurana
          </Typography>
        </Grid>
      </Grid>

      <Box
        padding={8}
        sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}
      >
        <Box>
          <Typography sx={{ color: "#9c5430" }} fontSize={25} fontWeight={600}>
            Looking for a surprise?
          </Typography>
          <Typography sx={{ color: "gray", fontSize: 15 }}>
            Enter your email to get a special discount code for your next
            purchase.
          </Typography>
          <Box
            sx={{
              border: "1px solid #9c5430",
              my: 3,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: 25,
            }}
          >
            <input
              className="subscriptionInput"
              placeholder="Your email address"
            />
            <button className="subscriptionBtn">Subscribe</button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Home;
